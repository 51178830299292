.jumpstart-box {
  border: none;
  width: 90%;
  margin: 10px 10px 10px 10px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: 'space-between';
  justify-content: 'space-between';
  -ms-flex-align: center;
  align-items: center;
  color: #fff !important;
  cursor: pointer;
  -webkit-box-shadow: 0 8px 6px -6px rgb(46, 44, 44);
  box-shadow: 0 8px 6px -6px rgb(46, 44, 44);
  min-width: 180;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.jumpstart-box:hover {
  -webkit-box-shadow: 0 12px 6px -6px rgb(46, 44, 44);
  box-shadow: 0 12px 6px -6px rgb(46, 44, 44);
}

.number-icon {
  font-size: 54px;
  float: left;
}

.content {
  width: 100%;
  padding-left: 78px;
}

.title {
  line-height: 20px;
  font-size: 20px;
  padding-bottom: 10px;
  height: 20px;
}

.number {
  line-height: 32px;
  font-size: 24px;
  height: 32px;
  margin-bottom: 0;
}

#countryCode {
  width: 200px;
}

@media screen and (max-width: 400px) {
  .content {
    width: 50%;
    padding-left: 30px;
  }
}
