@import '~antd/dist/antd.css';

.anticon-paper-clip {
  display: none;
}

.ant-form-item-label {
  width: 150px;
  text-align: start;
  margin-right: 10px;
}

.ant-picker-calendar-header{
  justify-content: center;
}